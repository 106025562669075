import React from "react";
import { getFormattedDate, getFormattedTime } from '../LiveSessions';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import Pagination from "../Pagination";

function MobileView({webinarSessions, openModule, pageNumber, setPageNumber, maxPageNumber }) {
  return (
    <div className="card-list">
      {webinarSessions.length === 0 && (<div>No data</div>)}
      {webinarSessions.map((ses) => (
        <div className="card" key={ses.slug} onClick={() => openModule(ses.academy.slug, ses.webinar.slug)}>
          <div className="academy-name"><ImportContactsIcon className="acedemy-icon" />{ses.academy.name}</div>
          <div className="session-name">{ses.formatted_name}</div>
          <div className="time">{getFormattedDate(ses.date)}, {getFormattedTime(ses.date)}</div>
          <div className="instructor">Instructor: {ses.webinar.instructors.map((ins) => ins.name).join(', ')}</div>
          <hr />
          <div className="action"></div>
        </div>
      ))}
      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        maxPageNumber={maxPageNumber}
      />
    </div>
  )
}

export default MobileView;
