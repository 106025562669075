import React, { useState } from "react";
import UserProfile from "../../../UserProfile/UserProfile";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from '@mui/icons-material/Edit';
// import LiveTvIcon from '@mui/icons-material/LiveTv';
import userService from "../../../../services/user.service";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function DesktopNav({ dashboard, calendar }) {
  const [logoutPanel, setLogoutPanel] = useState(false);


  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const handleEditNavigation = () => {
    window.location.href = "https://office2.nvisionu.com/app.html#/TeamDashboard"
  }

  // const handleLiveSessions = () => {
  //   window.location.href = "/live-sessions";
  // }

  const { t } = useTranslation();

  const user = userService.getUser();
  return (
    <nav className="header__desktop_nav">
      <div className="header__desktop_nav__links_container">
        <div className="links_wrapper">
        </div>
      </div>
      <div className="right_wrapper">
        <button
          className="right_wrapper__default"
          onClick={() => setLogoutPanel(!logoutPanel)}
        >
          <UserProfile
            isThereName={true}
            height="40px"
            width="40px"
            fontSize="0.875rem"
            marginRight="0.75rem"
          />
          <span>{user.first_name}</span>
          {logoutPanel ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </button>
        <div
          className="logout_wrapper"
          style={
            logoutPanel
              ? { height: "6.5rem", padding: "1rem 0.5rem" }
              : { height: "0px", padding: "0" }
          }
        >
          <button className="logout_btn" onClick={handleLogout}>
            <span>{t('Logout')}</span>
            <LogoutIcon style={{ fontSize: "1rem" }} />
          </button>
          <button className="edit_acc_btn" onClick={handleEditNavigation}>
            {t('Edit Account')}
            <EditIcon style={{ fontSize: "1rem" }} />
          </button>
          {/* <button className="live_sess_btn" onClick={handleLiveSessions}>
            {t('Live Sessions')}
            <LiveTvIcon style={{ fontSize: "1rem" }} />
          </button> */}
        </div>
      </div>
    </nav>
  );
}

export default DesktopNav;
