import React, { useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

function MobileView({ webinarSessions, openModule, month = 0 }) {
  const calendarRef = useRef(null);

  const handleCalendarEventClick = (e) => {
    const eventTitle = e.event._def.title;
    const ses = webinarSessions.find((session) => session.formatted_name === eventTitle);
    openModule(ses.academy.slug, ses.webinar.slug);
  }

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(new Date(calendarApi.getDate().setMonth(month)));
  }, [month]);

  return (
    <div className="calendar-view">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={webinarSessions.map((session) => ({...session, title: session.formatted_name}))}
        eventColor={"#6bcad2"}
        eventClick={ (e) => handleCalendarEventClick(e) }
        eventTimeFormat={{hour: 'numeric', minute: '2-digit'}}
      />
    </div>
  )
}

export default MobileView;
