import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header/Header";
import Select from "../../components/Select/Select";
import coreService from "../../services/core.service";
import { useNavigate } from "react-router-dom";

import TableView from "./Views/TableView";
import MobileView from "./Views/MobileView";
import CalendarView from "./Views/CalendarView";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import range from "../../helper/range";

export function getFormattedTime(date) {
  return new Date(date).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

const PAGE_SIZE = 10;
const MIN_MONTH = -12;
const MAX_MONTH = 6;

export function getFormattedDate(date) {
  const day = new Date(date).getDate();
  const daySuffix = (day % 10 === 1 && day !== 11) ? "st" :
                    (day % 10 === 2 && day !== 12) ? "nd" :
                    (day % 10 === 3 && day !== 13) ? "rd" : "th";

  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
  }) + ` ${day}${daySuffix}`;
}

function getMonthOptionValue(delta) {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + delta);
  return `${currentDate.getMonth() + 1} ${currentDate.getFullYear()}`
}

function getMonthOptionText(delta) {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + delta);
  const options = { month: "long", year: "numeric" };
  return currentDate.toLocaleDateString("en-US", options);
}

const options = range(MIN_MONTH, MAX_MONTH).map((i) => ({ value: i, label: getMonthOptionText(i)}));

function LiveSessions() {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState('table')
  const [isLoading, setIsLoading] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [webinarSessions, setWebinarSessions] = useState([]);
  const [optionIndex, setOptionIndex] = useState(options.findIndex((op) => op.value === 0));

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const openModule = (academySlug, moduleSlug) => {
    const url = `/academies/${academySlug}/?module=${moduleSlug}`;
    navigate(url);
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsLoading((prevLoading) => prevLoading + 1)
    coreService
      .getWebinarSessions({}, { limit: currentView  === 'table' ? PAGE_SIZE : 10000, offset: pageNumber, month: getMonthOptionValue(options[optionIndex].value).split(" ")[0], year: getMonthOptionValue(options[optionIndex].value).split(" ")[1] })
      .then((res) => {
        setWebinarSessions(res.data.records);
        setTotalCount(res.data.total_count);
      })
      .catch((err) => {console.log(err)})
      .finally(() => setIsLoading((prevLoading) => prevLoading - 1));
  }, [pageNumber, currentView, optionIndex]);

  const maxPageNumber = Math.ceil(totalCount / PAGE_SIZE);
  return (
    <div className="live-sessions">
      <Header dashboard={false} calendar={false} />
      <div className="live-sessions_container">
        <h1>{t("Live Sessions")}</h1>
        <div className="page-filters">
          <div className="month-filter">
            <Select
              options={options}
              value={options[optionIndex]}
              onSelect={(e) => {
                setPageNumber(1);
                setOptionIndex(options.findIndex((el) => el.value === e.value))
              }}
            />
            <button className="button" onClick={() => {setOptionIndex((curIdx) => Math.max(options.findIndex((el) => el.value === MIN_MONTH), curIdx - 1))}}><KeyboardArrowLeft /></button>
            <button className="button" onClick={() => {setOptionIndex((curIdx) => Math.min(options.findIndex((el) => el.value === MAX_MONTH), curIdx + 1))}}><KeyboardArrowRight /></button>
          </div>
          {!isMobile && (
            <div className="view-switch">
              <button
                className={currentView === 'calendar' ? 'active button' : 'button'}
                onClick={() => setCurrentView('calendar')}
                >
                  Calendar
                </button>
              <button
                className={currentView === 'table' ? 'active button' : 'button'}
                onClick={() => setCurrentView('table')}
                >
                  Table
                </button>
            </div>
          )}
        </div>

        {isLoading > 0 && <div>Loading...</div>}
        {!isLoading > 0 && !isMobile && currentView === 'table' && (
          <TableView
            webinarSessions={webinarSessions}
            openModule={openModule}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            maxPageNumber={maxPageNumber}
          />
        )}
        {!isLoading > 0 && !isMobile && currentView === 'calendar' && (
          <CalendarView
            webinarSessions={webinarSessions}
            openModule={openModule}
            month={new Date().getMonth()}
          />
        )}
        {!isLoading > 0 && isMobile && (
          <MobileView
            webinarSessions={webinarSessions}
            openModule={openModule}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            maxPageNumber={maxPageNumber}
          />
        )}
      </div>
    </div>
  )
}

export default LiveSessions;
