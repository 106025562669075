import React from 'react'
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { t } from 'i18next';


function DiscoverMoreCard({ discoverMoreAcademy }) {
  return (
    <div className="discover_more_container" onClick={() => {
      window.open(discoverMoreAcademy.link, '_blank').focus();
    }}>
      <div className="discover_more_content">
        <div
          className="image"
          style={{
            backgroundImage: `url(${discoverMoreAcademy.background_url})`,
            backgroundSize: 'cover',
            backgroundBlendMode: 'overlay',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
          }}
        >
          <div className="image_overlay" />
        </div>
        <div className="icon-wrapper">
          <LockIcon />
        </div>
        <div className="content">
          <h3 className="title">{discoverMoreAcademy.name || (discoverMoreAcademy.academy_collection && discoverMoreAcademy.academy_collection.name)}</h3>
          <p className="prices_wrapper">
            <span className={discoverMoreAcademy.sale_price ? "price discounted" : "price"}>${discoverMoreAcademy.price}</span>
            {discoverMoreAcademy.sale_price && <span className="sale_price">${discoverMoreAcademy.sale_price}</span>}
          </p>
          <p className="description">{discoverMoreAcademy.description || (discoverMoreAcademy.academy_collection && discoverMoreAcademy.academy_collection.description)}</p>
        </div>
      </div>
      <div className="actions-wrapper">
        <p className="goto-link">{discoverMoreAcademy.academy_collection ? t('Go to course page') : t('Find out more')}</p>
        <button className='goto-icon-wrapper'>
          <ArrowForwardOutlinedIcon className='goto-icon' />
        </button>
      </div>
    </div>
  );
}

export default DiscoverMoreCard
