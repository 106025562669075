import React from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function Pagination({ pageNumber, setPageNumber, maxPageNumber }) {
  return (
    <div className="pagination">
      {Array.from({ length: maxPageNumber  }, (_, i) => i + 1).map((page) => <div className={`page ${page === pageNumber ? 'active' : ''}`} key={page} onClick={() => setPageNumber(page)}>{page}</div>)}
      <div className="arrows">
        <div className={`arrow ${pageNumber > 1 ? 'active': ''}`} onClick={() => setPageNumber((current) => Math.max(current - 1, 1))}>
          <KeyboardArrowLeft />
        </div>
        <div className={`arrow ${pageNumber < maxPageNumber ? 'active': ''}`} onClick={() => setPageNumber((current) => Math.min(current + 1, maxPageNumber))} >
          <KeyboardArrowRight />
        </div>
      </div>
    </div>
  )
}

export default Pagination;