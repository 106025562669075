import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BackgroundWaveImage from "./assets/images/background-wave.svg";
import CompleteCertificate from "./routes/CompleteCertificate/CompleteCertificate";
import Inactive from "./routes/Inactive/Inactive";
import LoginAccessDenied from "./routes/LoginAccessDenied/LoginAccessDenied";
import LoginLoading from "./routes/LoginLoading/LoginLoading";
import userService from "./services/user.service";
import CalendarPage from "./routes/Calendar/CalendarPage";
import Academy from "./routes/Academy/Academy";
import Home from './routes/Home/Home';
import ProtectedView from './components/ProtectedView';
import { useTranslation } from "react-i18next";
import "./translations";
import { initGA } from "./analytics";
import TrackPageView from "./components/TrackPageVisit";
import LiveSessions from "./routes/LiveSessions/LiveSessions";

function App() {
  const [active, setActive] = useState(false)
  const [user, setUser] = useState(
    userService.getUser()
  );
  const backgroundImageStyle = {
    position: "absolute",
    top: "0",
    width: "100%",
    opacity: "0.1",
    zIndex: "-1",
  };

  useEffect(() => {
    initGA();
  }, [])

  const { i18n } = useTranslation();

  useEffect(() => {
    const language = user && user.language ? user.language : 'en';
    i18n.changeLanguage(language);
  }, [i18n, user]);

  useEffect(() => {
    if (user && user.active) {
      setActive(true)
    } else if(user && !user.active) {
      setActive(false)
    } else {
      setActive(false)
    }
  }, [user, active]);

  return (
    <Router>
      <div className="App">
        <img src={BackgroundWaveImage} alt="" style={backgroundImageStyle} />
        <Routes>
          <Route
            path="/login-loading"
            exact={true}
            element={<TrackPageView title="NVisionU - Login"><LoginLoading set_user={ setUser } /></TrackPageView>}
          />
          <Route path="/denied" element={<TrackPageView title="NVisionU - Inactive"><LoginAccessDenied /></TrackPageView>} />
          <Route path="/calendar" element={<TrackPageView title="NVisionU - Calendar"><ProtectedView><CalendarPage /></ProtectedView></TrackPageView>} />
          <Route path="/academies/:slug" element={<ProtectedView><Academy /></ProtectedView>} />
          <Route path="/inactive" element={<TrackPageView title="NVisionU - Inactive"><Inactive /></TrackPageView>} />
          <Route path="/complete-certificate" element={<TrackPageView title="NVisionU - Complete Certificate"><ProtectedView><CompleteCertificate /></ProtectedView></TrackPageView>} />
          <Route path="/live-sessions" element={<TrackPageView title="NVisionU - Live Sessions"><ProtectedView><LiveSessions /></ProtectedView></TrackPageView>} />
          <Route path="/" element={<TrackPageView title="NVisionU - Dashboard"><Home /></TrackPageView>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
