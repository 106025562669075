import React, { useEffect, useState } from 'react'
import Tile from '../Tile/Tile';
import coreService from "../../../../../../services/core.service";
import VideoIcon from "../../../../../../assets/images/module/video.svg";
import Moment from "react-moment";
import AcademyHeroImage from "../../../../../../assets/images/academy-hero.jpg";

import { useTranslation } from "react-i18next";

function TileContainer({academy}) {
  const [nextModuleThumbnail, setNextModuleThumbnail] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (academy.next_module && academy.next_module.next_module_video_url) {
      coreService
        .getModuleVideoThumbnail(academy.next_module.next_module_video_url.split("/").pop())
        .then((res) => {
          setNextModuleThumbnail(res.data);
        }).catch(e => {
          setNextModuleThumbnail(undefined);
        })
    }
    else
      setNextModuleThumbnail(undefined);
  }, [academy])

  const tileStyle = {}
  if(nextModuleThumbnail || academy.background) {
    tileStyle['backgroundImage'] = `url(${ academy.completed ? (academy.background ?? AcademyHeroImage) : (nextModuleThumbnail ?? academy.background ?? AcademyHeroImage)})`;
    tileStyle['backgroundSize'] = 'cover';
    tileStyle['backgroundBlendMode'] = 'overlay';
    tileStyle['backgroundColor'] = 'rgba(0, 0, 0, 0.25)';

  }
  return (
    <div className="tile_container">
          <div className='tile_container_overlay'></div>
          { academy.next_module.name &&
          <Tile
            tile_title={academy.next_module.name}
            tile_header={academy.name}
            style={{...tileStyle, display: academy.completed ? 'none' : 'flex'}}
            academySlug={academy.slug}
            slug={academy.next_module.slug}
          />
          }
          {
            academy.completed ?
              <Tile
                tile_title={academy.name}
                tile_header={t('Course')}
                style={tileStyle}
                academySlug={academy.slug}
                slug={academy.next_module.slug}
                completed
              />
          : null
          }
    </div>
  )
}

export default TileContainer
