import React, { useState, useRef, useEffect } from 'react'
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import coreService from "../../../services/core.service";


function Filter({set_current_event_academy, current_event_academy, all_webinar_events, set_events, events}) {

  // academies will list all incomplete academy collection -- this will be used as filter options
  const [academies, setAcademies] = useState([]);
  const [isFilterButton, setIsFilterButton] = useState(false);
  const filterAcademyOptions = useRef()
  const filterStyle = {
    height: isFilterButton ? `${academies.length * 37 + 32}px` : "0px",
    paddingTop: isFilterButton ? "1rem" : "0",
    borderLeft: isFilterButton ? "1px solid #21273c10" : "none",
    borderRight: isFilterButton ? "1px solid #21273c10" : "none",
    borderBottom: isFilterButton ? "1px solid #21273c10" : "none"
  };

  useEffect(() => {
    // options for filter academy -- this will list incomplete academies
    coreService.getAcademies().then((res) => {
      const records = res.data.records;
      setAcademies(() => {
        const academy = ["All Academies"];
        const academyData = [];
        for (let i = 0; i < records.length; i++) {
          if (!records[i].completed) {
            academy.push(records[i].name);
          }
        }
        return academy
      })
    })

  }, [])

    // setting filter academy events
    useEffect(() => {
      const filteredEvents = []
      all_webinar_events.filter(webinar => {
        if(webinar.academy && webinar.academy.toLowerCase() === current_event_academy.toLowerCase()) {
          filteredEvents.push(webinar)
        }
      })
      set_events(filteredEvents)
    }, [current_event_academy])


    const handleFilterAcademy = (academy, e) => {
        set_current_event_academy(academy)
        const lists = filterAcademyOptions.current.children
        for(let i = 0; i < lists.length; i++) {
          lists[i].classList.remove('active-academy')
        }
        e.target.classList.add('active-academy')
        setIsFilterButton(false)
    }

  return (
    <>
      <button
        className="filter_academy_button filter-button"
        onClick={() => setIsFilterButton(!isFilterButton)}
      >
        <h3 className="filter-button">{current_event_academy}</h3>
        {isFilterButton ? (
          <ExpandLessIcon className="filter-button" />
        ) : (
          <KeyboardArrowDownIcon className="filter-button" />
        )}
      </button>

      <nav
        className="filter_nav"
        style={filterStyle}
        ref={filterAcademyOptions}
      >
        {academies.map((academy, i) => {
          return (
            <li
              key={i}
              className={i === 0 ? "active-academy" : null}
              onClick={(e) => handleFilterAcademy(academy, e)}
            >
              {` ${academy} `}

            </li>
          );
        })}
      </nav>
    </>
  );
}

export default Filter;
