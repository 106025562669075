import React from "react";
import { getFormattedDate, getFormattedTime } from '../LiveSessions'
import Pagination from "../Pagination";

function TableView({ webinarSessions, openModule, pageNumber, setPageNumber, maxPageNumber }) {
  return (
    <table className="table-view">
      <thead>
        <tr>
          <th>Session name</th>
          <th>Date</th>
          <th>Time</th>
          <th>Course</th>
          <th>Instructor</th>
        </tr>
      </thead>
      <tbody>
        {webinarSessions.length === 0 && (
          <tr>
            <td colSpan={5}>No data</td>
          </tr>
        )}
        {webinarSessions.map((ses) => (
          <tr key={ses.slug} onClick={() => openModule(ses.academy.slug, ses.webinar.slug)}>
            <td>{ses.formatted_name}</td>
            <td>{getFormattedDate(ses.date)}</td>
            <td>{getFormattedTime(ses.date)}</td>
            <td>{ses.academy.name}</td>
            <td>{ses.webinar.instructors.map((ins) => ins.name).join(', ')}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={5}>
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              maxPageNumber={maxPageNumber}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default TableView
